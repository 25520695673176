<template>
    <div ref="content" class="newCBG">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'eCampaignBlockGroupList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-2">
                    <p class="title" data-v-step="step-title">
                        {{ $t('cbgViews.newCbg.header')}}
                    </p>
                </div>
            </div>
        </div>

    <wizard :showHeader="false" :showFinishButton="false" 
            :prevButtonText="$t('cbgViews.newCbg.wizard.btnBack')" 
            :nextButtonText="$t('cbgViews.newCbg.wizard.btnNext')">

        <!-- Info -->
        <wizard-tab :before-change="tabInfoComplete">
            <template slot="label">
                <i class="now-ui-icons users_circle-08"></i>
                {{ $t('cbgViews.newCbg.wizard.tabInfo.title')}}
            </template>

            <div class="row">
                <div class="col-12">
                    <h3> {{ $t('cbgViews.newCbg.wizard.tabInfo.header')}}</h3>
                    <p>
                        {{ $t('cbgViews.newCbg.wizard.tabInfo.text')}}
                    </p>
                </div>

                <div class="col-12 col-md-8">
                    <div class="form-group">
                        <label class="">{{ $t('cbgViews.newCbg.wizard.tabInfo.form.cbgName.label')}}</label>
                        <fg-input :placeholder="$t('cbgViews.newCbg.wizard.tabInfo.form.cbgName.placeholder')" v-model="cbgName" :error="modelValidations.cbgName.msg"></fg-input>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="form-group" data-v-step="step-cc">
                        <label>
                            {{ $t('cbgViews.newCbg.wizard.tabInfo.form.cbgCC.label')}}
                            <el-tooltip :content="$t('cbgViews.newCbg.wizard.tabInfo.form.cbgCC.toolTip')" :open-delay="300" placement="top">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </el-tooltip>
                        </label>
                        <fg-input :error="modelValidations.cbgCC.msg">
                            <el-select class="select-primary" v-model="cbgCC" :placeholder="$t('cbgViews.newCbg.wizard.tabInfo.form.cbgCC.placeholder')">
                                <el-option v-for="cc in formatedCCs"
                                           class="select-primary"
                                           :value="cc.key"
                                           :label="cc.label"
                                           :key="cc.key">
                                </el-option>
                            </el-select>
                        </fg-input>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group" data-v-step="step-desc">
                        <label class="">{{ $t('cbgViews.newCbg.wizard.tabInfo.form.cbgDesc.label')}}</label>
                        <el-input type="textarea" v-bind:class="{ 'is-novalid': (modelValidations.cbgDesc.msg.length > 0) }"
                                  :rows="2"
                                  :placeholder="$t('cbgViews.newCbg.wizard.tabInfo.form.cbgDesc.placeholder')"
                                  v-model="cbgDesc">
                        </el-input>
                        <div class="row" v-if="modelValidations.cbgDesc.msg.length > 0">
                            <div class="col text-left">
                                <span class="validation-summary">
                                    {{modelValidations.cbgDesc.msg}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </wizard-tab>

        <!-- CB -->
        <wizard-tab :before-change="tabCbComplete">
            <template slot="label">
                <i class="now-ui-icons users_circle-08"></i>
                {{ $t('cbgViews.newCbg.wizard.tabCB.title')}}
            </template>

            <div class="row">
                <div class="col-12">
                    <h3> {{ $t('cbgViews.newCbg.wizard.tabCB.header')}}</h3>
                    <p>
                        {{ $t('cbgViews.newCbg.wizard.tabCB.text')}}
                    </p>
                </div>
            </div>

            <selected-campaign-block-table v-bind:campaignBlocks="cbgBlocks" ref="selTable" @selectedChanged="selectChangeCBG" :entityHID="entityHID">
            </selected-campaign-block-table>

            <div class="row mt-5" v-if="modelValidations.cbgBlocks.msg.length > 0">
                <div class="col text-center">
                    <span class="validation-summary validation-cbgBlocks">
                        {{modelValidations.cbgBlocks.msg}}
                    </span>
                </div>
            </div>

            <div class="row mt-3 justify-content-center">
                <div class="col-12 col-md-auto text-center text-md-right">
                    <p-button type="success" @click="openCbModal" class="w-100" :disabled="disableAddingCb">
                        {{ $t('cbgViews.newCbg.btnAddCB')}}
                    </p-button>
                </div>
            </div>

        </wizard-tab>

        <!-- Timing -->
        <wizard-tab>
            <template slot="label">
                <i class="now-ui-icons users_circle-08"></i>
                {{ $t('cbgViews.newCbg.wizard.tabTiming.title')}}
            </template>

            <div class="row">
                <div class="col-12">
                    <h3> {{ $t('cbgViews.newCbg.wizard.tabTiming.header')}}</h3>
                    <p>
                        {{ $t('cbgViews.newCbg.wizard.tabTiming.text')}}
                    </p>
                </div>
            </div>

            <div class="row align-items-center mt-3">
                <div class="col-12 col-md-6">
                    <h6>
                        {{ $t('cbgViews.newCbg.wizard.tabTiming.total.header')}}
                    </h6>
                    <p class="text-muted">
                        {{ $t('cbgViews.newCbg.wizard.tabTiming.total.text1')}}
                        <br />
                        {{ $t('cbgViews.newCbg.wizard.tabTiming.total.text2')}}
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <p-progress :values="cbgProgress" class="cbg-progress">
                            </p-progress>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <h4 style="margin-bottom: 0px; margin-top: 0px;">{{ $t('cbgViews.newCbg.wizard.tabTiming.total.label', [this.cbgSending + this.cbgWaiting])}}</h4>
                        </div>
                    </div>

                </div>
            </div>

            <el-divider></el-divider>

            <div class="row align-items-center">
                <div class="col-12 col-md-6">
                    <h6>
                        {{ $t('cbgViews.newCbg.wizard.tabTiming.sending.header')}}
                    </h6>
                    <p class="text-muted">
                        {{ $t('cbgViews.newCbg.wizard.tabTiming.sending.text1')}}
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <div class="block">
                        <el-slider v-model="cbgSending" :min="cbgSendingMin" :max="cbgSendingMax" show-input class="slider-success" data-v-step="step-sendphase">
                        </el-slider>
                    </div>
                </div>
            </div>

            <el-divider></el-divider>

            <div class="row align-items-center mt-3">
                <div class="col-12 col-md-6">
                    <h6>
                        {{ $t('cbgViews.newCbg.wizard.tabTiming.waiting.header')}}
                    </h6>
                    <p class="text-muted">
                        {{ $t('cbgViews.newCbg.wizard.tabTiming.waiting.text1')}}
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <div class="block">
                        <el-slider v-model="cbgWaiting" :min="cbgWaitingMin" :max="cbgWaitingMax" show-input class="slider-warning" data-v-step="step-waitphase">
                        </el-slider>
                    </div>
                </div>
            </div>

            <div class="row mt-3" v-if="modelValidations.summary.msg.length > 0">
                <div class="col text-center">
                    <span class="validation-summary">
                        {{modelValidations.summary.msg}}
                    </span>
                </div>
            </div>

            <div class="row mt-5 justify-content-center">
                <div class="col-12 col-md-auto text-center text-md-right">
                    <p-button type="success" @click="createTemplate" class="w-100">
                        {{ $t('cbgViews.newCbg.btnCreate')}}
                    </p-button>
                </div>
            </div>
        </wizard-tab>

    </wizard>


    <!-- Modal dialog for select CBs -->
    <modal :show.sync="modal.show" class="modal-cbs">
        <h5 slot="header" class="modal-title">{{ $t('cbgViews.newCbg.modal.title')}}</h5>
        <div class="cb-container" ref="cbcontainer">
            <campaign-blocks v-bind:campaignBlocks="campaignBlocks" :enableSelect="true" ref="allList" :disableAddBtn="disableAddingCb"
                             @selectedChanged="selectChangeCBG" :entityHID="entityHID">
                <template v-slot:cardheader>
                    <h4 class="card-title" data-v-step="step-cbt-title">
                        {{ $t('cbgViews.newCbg.modal.header')}}
                    </h4>
                    <p>
                        {{ $t('cbgViews.newCbg.modal.text')}}
                    </p>
                </template>
            </campaign-blocks>
        </div>

        <p-button type="info" class="btn-go-top" round @click="goCbModalTop">
            <i class="fa-solid fa-angles-up"></i>
        </p-button>

        <div slot="footer" class="d-flex justify-content-center w-100">
            <p-button type="info" wide @click.native="modal.show = false">{{ $t('cbgViews.newCbg.modal.btnClose')}}</p-button>
        </div>
    </modal>

    </div>
</template>

<script>
    import { Modal, Collapse, CollapseItem, Card, Checkbox, Wizard, WizardTab } from 'src/components/UIComponents'
    import { Select, Option, Input, Slider, Divider } from 'element-ui'
    import swal from 'sweetalert2'
    import PProgress from 'src/components/UIComponents/Progress.vue'
    import SelectedCampaignBlockTable from 'src/components/UIComponents/CampaignBlock/SelectedCampaignBlockTable.vue'
    import CampaignBlocks from 'src/components/UIComponents/CampaignBlock/CampaignBlocks.vue'

    export default {
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            CollapseItem,
            Collapse,
            [Checkbox.name]: Checkbox,
            Card,
            [Input.name]: Input,
            [Slider.name]: Slider,
            [Divider.name]: Divider,
            PProgress,
            SelectedCampaignBlockTable,
            CampaignBlocks,
            Wizard,
            WizardTab,
            Modal,
        },
        data() {
            return {
                entityHID: null,
                campaignBlocks: [],
                cbgName: null,
                cbgDesc: null,
                cbgCC: null,
                cbgSending: 0,
                cbgWaiting: 0,
                modelValidations: {
                    cbgName: {
                        msg: '',
                    },
                    cbgDesc: {
                        msg: '',
                    },
                    cbgCC: {
                        msg: '',
                    },
                    cbgBlocks: {
                        msg: '',
                    },
                    summary: {
                        msg: '',
                    },
                },
                refreshCbgBlocks: 0,
                modal: {
                    show: false,
                }
            }
        },
        methods: {
            initComponent: function () {
                this.loadCampBlocks();
                this.cbgWaiting = Math.trunc((this.cbgWaitingMax - this.cbgWaitingMin) / 2);
            },
            loadCampBlocks() {
                if (!this.entityHID) return;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.content));
                this.axios.post(this.$root.config.entity.campBlock.getCampaignBlocksUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.campaignBlocks = response.data;
                        vueThis.campaignBlocks.forEach((item) => {
                            item.selected = false;
                        });                        
                        loader.hide();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            clearValidation: function () {
                this.clearValidationTabInto();
                this.clearValidationTabCb();

                this.modelValidations.summary.msg = "";
            },
            validate: function () {
                var result = true;
                this.clearValidation();

                //------- tab Info
                result = this.validateTabInto();

                //--- tabCB
                result = this.validateTabCb();

                // summary
                if (result) {
                    this.modelValidations.summary.msg = "";
                }
                else {
                    this.modelValidations.summary.msg = this.$t('cbgViews.newCbg.errSummary');
                }

                return result;
            },
            createTemplate: function () {
                if (this.validate()) {
                    var cModel = {
                        name: this.cbgName.trim(),
                        description: this.cbgDesc.trim(),
                        cultureCode: this.cbgCC,
                        blockHIDs: this.cbgBlocks.map(x => x.hid),
                        sending: this.cbgSending,
                        waiting: this.cbgWaiting,
                    };
                    swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                    let vueThis = this;
                    this.axios.post(this.$root.config.entity.campBlockGroup.createCampaignBlockGroupUrl(this.entityHID), cModel)
                        .then(response => {
                            swal.close();
                            vueThis.$router.push({ name: 'eCampaignBlockGroupList' });
                            swal.fire({
                                icon: 'success',
                                title: vueThis.$t('cbgViews.newCbg.createAlert.success'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }).catch(function (error) {
                            swal.close();
                            if (error.response.status == 400) {
                                let respMsg = error.response.data;
                                swal.fire({
                                    title: vueThis.$t('cbgViews.newCbg.createAlert.failed'),
                                    text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: cModel.name }),
                                    icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }
                            else {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                            }
                        });
                }
            },
            selectChangeCBG(gcb, value) {
                //console.log("selectChangeCBG", gcb, value);
                this.campaignBlocks.find(c => c.hid === gcb.hid).selected = value;
                this.refreshCbgBlocks++;
                this.$refs.selTable.refresh();
                this.$refs.allList.refresh();
            },
            //-----------
            tabInfoComplete() {               
                return this.validateTabInto();
            },
            validateTabInto() {
                var result = true;
                this.clearValidationTabInto();
                if (this.cbgName == null) {
                    this.modelValidations.cbgName.msg = this.$t('cbgViews.newCbg.wizard.tabInfo.form.cbgName.errReq');
                    result = false;
                }
                else {
                    if (this.cbgName.trim().length == 0) {
                        this.modelValidations.cbgName.msg = this.$t('cbgViews.newCbg.wizard.tabInfo.form.cbgName.errReq');
                        result = false;
                    }
                    if (this.cbgName.trim().length > this.$root.config.maxCampainBlockGroupNameLength) {
                        this.modelValidations.cbgName.msg = this.$t('cbgViews.newCbg.wizard.tabInfo.form.cbgName.errMaxLen', [this.$root.config.maxCampainBlockGroupNameLength]);
                        result = false;
                    }
                }
                if (this.cbgDesc == null) {
                    this.modelValidations.cbgDesc.msg = this.$t('cbgViews.newCbg.wizard.tabInfo.form.cbgDesc.errReq');
                    result = false;
                }
                else {
                    if (this.cbgDesc.trim().length == 0) {
                        this.modelValidations.cbgDesc.msg = this.$t('cbgViews.newCbg.wizard.tabInfo.form.cbgDesc.errReq');
                        result = false;
                    }
                    if (this.cbgDesc.trim().length > this.$root.config.maxCampainBlockGroupDescLength) {
                        this.modelValidations.cbgDesc.msg = this.$t('cbgViews.newCbg.wizard.tabInfo.form.cbgDesc.errLen', [this.$root.config.maxCampainBlockGroupDescLength]);
                        result = false;
                    }
                }
                if (this.cbgCC == null) {
                    this.modelValidations.cbgCC.msg = this.$t('cbgViews.newCbg.wizard.tabInfo.form.cbgCC.errReq');
                    result = false;
                }

                return result;
            },
            clearValidationTabInto: function () {
                this.modelValidations.cbgName.msg = "";
                this.modelValidations.cbgDesc.msg = "";
                this.modelValidations.cbgCC.msg = "";
            },
            tabCbComplete() {
                return this.validateTabCb();
            },
            validateTabCb() {
                var result = true;
                this.clearValidationTabCb();
                if (this.cbgBlocks.length == 0) {
                    this.modelValidations.cbgBlocks.msg = this.$t('cbgViews.newCbg.wizard.tabCB.errReq');
                    result = false;
                }
                if (this.cbgBlocks.length > this.$root.config.maxBlocksInCampainBlockGroup) {
                    this.modelValidations.cbgBlocks.msg = this.$t('cbgViews.newCbg.wizard.tabCB.errMax', [this.$root.config.maxBlocksInCampainBlockGroup]);
                    result = false;
                }
                return result;
            },
            clearValidationTabCb: function () {
                this.modelValidations.cbgBlocks.msg = "";
            },
            //-----------
            openCbModal() {
                this.modal.show = true;
                //setTimeout(() => { this.goCbModalTop(); }, 500);
            },
            goCbModalTop() {
                this.$refs.cbcontainer.scrollTop = 0;
            },
        },
        computed: {
            cultureCodes() {
                if (this.campaignBlocks == null || this.campaignBlocks.length == 0)
                    return [];

                let res = [];
                res.push("multi");
                this.campaignBlocks.forEach((item) =>
                {
                    if (!res.includes(item.cultureCode)) {
                        res.push(item.cultureCode);
                    }
                });
                return res;
            },
            cbgSendingMin() {
                if (this.cbgBlocks == null || this.cbgBlocks.length == 0)
                    return 0;

                return this.cbgBlocks.length * this.$root.config.minCampainBlockSendingDay;
            },
            cbgSendingMax() {
                if (this.cbgBlocks == null || this.cbgBlocks.length == 0)
                    return 0;

                return this.cbgBlocks.length * this.$root.config.maxCampainBlockSendingDay;
            },
            cbgWaitingMin() {
                return this.$root.config.minCampainBlockGroupWaitingDay;
            },
            cbgWaitingMax() {
                return this.$root.config.maxCampainBlockGroupWaitingDay;
            },
            cbgProgress() {
                let all = this.cbgSending + this.cbgWaiting;
                return [
                    { type: 'success', value: (this.cbgSending / all)*100 },
                    { type: 'warning', value: (this.cbgWaiting / all)*100 },
                ];
            },
            cbgBlocks() {
                this.refreshCbgBlocks;
                return this.campaignBlocks.filter(c => c.selected == true);
            },
            disableAddingCb() {
                return this.cbgBlocks.length >= this.$root.config.maxBlocksInCampainBlockGroup;
            },
            formatedCCs() {
                return this.cultureCodes.map(c => { return { key: c, label: c.toUpperCase() } })
            },
        },
        watch: {
            cbgName: function (value) {
                this.validateTabInto();
            },
            cbgDesc: function (value) {
                this.validateTabInto();
            },
            cbgCC: function (value) {
                this.validateTabInto();
            },
            cbgBlocks: function (value) {
                this.validateTabCb();
                this.$refs.selTable.refresh();
                this.cbgSending = this.cbgSendingMin + Math.trunc((this.cbgSendingMax - this.cbgSendingMin) / 2);
            },
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = parseInt(this.$route.params.eid);
                    if (!this.$store.getters.appState.entity.manualControl) {
                        this.$router.push({ name: "aDashboard" });
                    }
                    else {
                        this.initComponent();
                    }
                }
            }
        },
        mounted() {
            this.initComponent();
        },
        created() {
            this.entityHID = this.$route.params.ehid;
        }
    }

</script>

<style scoped>
</style>