<template>
    <div class="cb-list" ref="content">
        <div class="mb-3" data-v-step="step-cbfilter">
            <div class="btn-group cb-btn-group d-none d-md-block">
                <p-button type="primary" round outline nativeLabel @click="cbFilterStatus('cb-all')" :class="{active: cbFilter.status === 'cb-all' }"> <i class="fa fa-list"></i> {{$t('cbViews.cbList.filter.btnAll')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="cbFilterStatus('cb-standard')" :class="{active: cbFilter.status === 'cb-standard' }"> <i class="fa fa-globe"></i> {{$t('cbViews.cbList.filter.btnStandard')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="cbFilterStatus('cb-customizable')" :class="{active: cbFilter.status === 'cb-customizable' }"> <i class="fa fa-sliders"></i> {{$t('cbViews.cbList.filter.btnCustom')}}</p-button>
            </div>
            <div class="row d-md-none">
                <div class="col-12">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="cbFilterStatus('cb-all')" :class="{active: cbFilter.status === 'cb-all' }"> <i class="fa fa-list"></i> {{$t('cbViews.cbList.filter.btnAll')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="cbFilterStatus('cb-standard')" :class="{active: cbFilter.status === 'cb-standard' }"> <i class="fa fa-globe"></i> {{$t('cbViews.cbList.filter.btnStandard')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="cbFilterStatus('cb-customizable')" :class="{active: cbFilter.status === 'cb-customizable' }"> <i class="fa fa-sliders"></i> {{$t('cbViews.cbList.filter.btnCustom')}}</p-button>
                </div>
            </div>
        </div>

        <campaign-blocks :campaignBlocks="preFilteredCampBlocks" :entityHID="entityHID">
            <template v-slot:cardheader>
                <div class="row" >
                    <div class="col d-flex justify-content-start">
                        <h4 class="card-title">{{$t('cbViews.cbList.header')}}</h4>
                    </div>
                    <div class="col text-right">
                        <el-tooltip :content="$t('cbViews.cbList.btnRefreshTooltip')" :open-delay="300" placement="top">
                            <p-button type="neutral" class="mr-2" icon @click="refresh(true)">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </p-button>
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </campaign-blocks>
    </div>
</template>

<script>
    import { Input, Select, Option, Upload, ColorPicker } from 'element-ui'
    import { Card,  } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import CampaignBlocks from 'src/components/UIComponents/CampaignBlock/CampaignBlocks.vue'


    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Upload.name]: Upload,
            [ColorPicker.name]: ColorPicker,
            CampaignBlocks,
        },
        data() {
            let vueThis = this;
            return {
                entityHID: null,
                campBlocks:[],
                cbFilter: {
                    status: 'sc-all'
                },
            }
        },
        methods: {
            initComponent: function () {
                this.loadCampBlocks();
            },
            loadCampBlocks(force) {
                if (force == undefined) force = false;
                if (!this.entityHID) return;
                let vueThis = this;
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.campBlock.getCampaignBlocksUrl(this.entityHID), null, { params: { force: force } })
                    .then(response => {                        
                        this.campBlocks = response.data;
                        swal.close();
                    }).catch(function (error) {
                        swal.close();
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            cbFilterStatus(status) {
                //this.cbFilter.status = status;
                if (this.cbFilter.status != status) {
                    this.$router.push({ name: 'eCampaignBlockList', query: { s: status } });
                }
                else {
                    this.cbFilter.status = status;
                }
            },
            refresh(force) {
                this.loadCampBlocks(force);
            },
        },
        computed: {
            //--------------------------
            preFilteredCampBlocks() {
                switch (this.cbFilter.status) {
                    case 'cb-standard':
                        return this.campBlocks.filter(c => !c.customizable);
                    case 'cb-customizable':
                        return this.campBlocks.filter(c => c.customizable);
                    default:
                        return this.campBlocks;
                }
            },
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;

                    let s = this.$route.query.s;
                    if (s == undefined) s = 'cb-all';
                    this.cbFilter.status = s;

                    this.initComponent();
                }
            },
        },
        created() {
            this.entityHID = this.$route.params.ehid;

            let s = this.$route.query.s;
            if (s == undefined) s = 'cb-all';
            this.cbFilter.status = s;

        }
    }
</script>

<style scoped>
</style>