<template>
    <div class="campaigns-scheduler" ref="campaignScheduler">
        <Calendar renderStyle="custom" ref="calendarInstance"
                  :data-source="calendarDataSource"
                  :customDataSourceRenderer="customDataRenderer"
                  roundRangeLimits="true"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  weekStart="1"
                  @click-day="onClickDayHandler"
                  :customDayRenderer="customDayRenderer"
                  language="en">
        </Calendar>
    </div>
</template>
<script>
    import { CampaignTools } from 'src/lpLibrary.js'
    import Calendar from 'v-year-calendar';

    export default {
        name: 'campaign-scheduler',
        components: {
            Calendar
        },
        props: {
            newCampaign: {
                id: -1,
                name: "",
                startDate: null,
                endDate: null,
                color: '',
            },
            minDate: Date,
            maxDate: Date,
            campaignSchedulerData: Object,
        },
        data() {
            return {
                calendarDataSource: [],
            }
        },
        computed: {
            today: () =>  new Date(Date.now()),
        },
        methods: {
            initComponent: function () {
            },
            customDataRenderer(elem, date, events) {
                //console.log("data", elem, date, events);                
                if (events.length > 0) {
                    const parent = elem.parentElement;
                    let pHeight = 28;
                    let iHeight = Math.max(Math.round(pHeight / events.length), 1);
                    let boxShadow = "";
                    let sumHeights = 0;
                    let cNames = "";
                    let newCampIsPlaced = false;
                    for (var i = 0; i < events.length; i++) {
                        if (boxShadow != "") {
                            boxShadow += ",";
                        }
                        if (i == (events.length - 1))
                            iHeight = pHeight - sumHeights;
                        boxShadow += `inset 0 -${(i + 1) * iHeight}px 0 0 ${events[i].color}`;
                        sumHeights += iHeight;
                        if (events[i].id == -1) {
                            newCampIsPlaced = true;
                            continue;
                        }
                        cNames = `<span>${events[i].name}</span>` + cNames;
                    }
                    if (newCampIsPlaced)
                        cNames = `<span>${this.$t('campSheduler.nCamp') }: ${this.newCampaign.name}</span>` + cNames;
                    if (events.some(c=>c.isFull == true)) {
                        cNames = cNames + `<span>${this.$t('campSheduler.campLimitEx')}</span>`;
                    }

                    //console.log(cNames);
                    let dayTooltip = 
`Info
<div class='text-left d-flex flex-column'>
    ${cNames}
</div>
`;
                    parent.style.boxShadow = boxShadow;
                    parent.insertAdjacentHTML('beforeend', `<span class='info-box'>${dayTooltip}</span>`);
                }
                if (this.newCampaign.startDate != undefined) {
                    if (date.getFullYear() === this.newCampaign.startDate.getFullYear() && date.getMonth() === this.newCampaign.startDate.getMonth() && date.getDate() === this.newCampaign.startDate.getDate()) {
                        elem.classList.add("c-startday");
                    }
                }
            },
            onClickDayHandler(dayObj) {
                this.$emit('onClickDay', dayObj.date);
            },
            customDayRenderer(elem, date) {
                if (this.today.getFullYear() === date.getFullYear() && this.today.getMonth() === date.getMonth() && this.today.getDate() === date.getDate()) {
                    elem.classList.add("today");
                }
            },
        },
        watch: {
            newCampaign: {
                deep: true,
                handler(val) {
                    if (val.startDate == null || val.startDate == undefined) return;
                    if (val.endDate == null || val.endDate == undefined) return;

                    let idx = this.calendarDataSource.findIndex(c => c.id == val.id);
                    if (idx == -1) {
                        this.calendarDataSource.push(val);
                    }
                    else {
                        this.calendarDataSource[idx] = val;
                        this.$refs.calendarInstance.calendar.render();
                    }
                }
                
            },
            campaignSchedulerData: {
                deep: true,
                handler(val) {
                    let vueThis = this;
                    val.campaignCountDays.forEach(function (item, index) {
                        let color = '#fbc658'; // ncastecne
                        let isFull = false;
                        if (item.count >= val.maxConcurrentCampaigns) {
                            color = "#ef8157"; // plno
                            isFull = true;
                        }
                        vueThis.calendarDataSource.push({
                            id: new Date(item.date).getTime(),
                            name: vueThis.$t('campSheduler.concurrCamps') + item.count,
                            color: color,
                            startDate: new Date(item.date),
                            endDate: new Date(item.date),
                            countCampaigns: item.count,
                            isFull: isFull,
                        });
                    });
                    this.$refs.calendarInstance.calendar.render();
                }
            },
        },
    }
</script>
<style>

</style>