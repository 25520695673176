<template>
    <div class="selectecd-cb-table">
        <div class="row">
            <div class="col-12" ref="table">
                <el-table v-bind:data="queriedData" ref="eltable" :empty-text="$t('campaignBlock.selectedCbTable.table.noData')"
                          :default-sort="{ prop: 'name', order: 'ascending'}"
                          @sort-change="handleSortChange">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="mb-1 px-5 py-3 select-cb-detail">
                                <campaign-block-detail :campaignBlock="props.row" :diffs="[props.row.difficulty]" :CCs="[props.row.cultureCode]" :entityHID="entityHID"></campaign-block-detail>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" sortable="custom" :label="$t('campaignBlock.selectedCbTable.table.colName.header')">
                        <template slot-scope="props">
                            <el-tooltip :content="$t('campaignBlock.selectedCbTable.table.colName.smsTooltip')" :open-delay="300" placement="top" v-if="props.row.channels.includes('sms')">
                                <i class="fa-regular fa-comment-dots" aria-hidden="true"></i>
                            </el-tooltip>
                            <el-tooltip :content="$t('campaignBlock.selectedCbTable.table.colName.emailTooltip')" :open-delay="300" placement="top" v-if="props.row.channels.includes('email')">
                                <i class="fa-regular fa-envelope" aria-hidden="true"></i>
                            </el-tooltip>
                            <span class="ml-2">{{props.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="description" :label="$t('campaignBlock.selectedCbTable.table.colDesc')">
                    </el-table-column>
                    <el-table-column prop="cultureCode" :width="160" sortable="custom" :label="$t('campaignBlock.selectedCbTable.table.colCC')" align="center">
                        <template slot-scope="props">
                            {{props.row.cultureCode.toUpperCase()}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="difficulty" sortable="custom" :label="$t('campaignBlock.selectedCbTable.table.colDiff')" :width="150" align="center">
                    </el-table-column>
                    <el-table-column :width="120" align="center" class-name="td-actions">
                        <template slot="header" slot-scope="scope">
                            <span data-v-step="step-actions">{{$t('campaignBlock.selectedCbTable.table.colActions.header')}}</span>
                        </template>
                        <template slot-scope="props">
                            <el-tooltip :content="$t('campaignBlock.selectedCbTable.table.colActions.btnRemove')" :open-delay="300" placement="top" v-if="props.row.selected">
                                <p-button type="danger" icon @click="selectCBG(props.row, false)">
                                    <i class="fa fa-times"></i>
                                </p-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>

            </div>
        </div>
    </div>
</template>

<script>
    import { Card, Pagination } from 'src/components/UIComponents'
    import { Select, Option, Table, TableColumn, Button } from 'element-ui'
    import CampaignBlockDetail from 'src/components/UIComponents/CampaignBlock/CampaignBlockDetail.vue'
    import DropdownCheck from 'src/components/UIComponents/DropdownCheck.vue'


    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'selected-campaign-block-table',
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            [Button.name]: Button,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            CampaignBlockDetail,
            DropdownCheck,
        },
        props: {
            campaignBlocks: {
                type: Array,
                required: true
            },
            entityHID: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                sortObject: { order: 'ascending', name: 'name' },
            }
        },
        methods: {
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },
            selectCBG(row, value) {
                this.$emit('selectedChanged', row, value);
            },   
            refresh() {
                const name = this.sortObject.name;
                const order = this.sortObject.order;
                this.$refs.eltable.clearSort();
                this.$refs.eltable.sort(name, order);
            },
        },
        computed: {
            //--------------------
            queriedData() {
                var cThis = this;
                let result = this.campaignBlocks;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); });
            },
        },
        watch: {

        },
        created(){

        },
    }
</script>

<style scoped>
</style>