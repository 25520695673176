<template>
    <div pref="content">
        <div class="row">
            <div class="col d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'eCampaignsList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-2">
                    <p class="title">
                        {{$t('entityViews.newCamp.header')}}
                    </p>
                </div>
            </div>
        </div>

        <card>
            <h4 slot="header" class="card-title">{{$t('entityViews.newCamp.settingsCard.header')}}</h4>

            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="form-group" data-v-step="step-name">
                        <label class="">{{$t('entityViews.newCamp.settingsCard.form.name.label')}}</label>
                        <fg-input :placeholder="$t('entityViews.newCamp.settingsCard.form.name.placeholder')" v-model="campaignName" :error="modelValidations.campaignName.msg"></fg-input>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="form-group" data-v-step="step-training">
                        <label>
                            {{$t('entityViews.newCamp.settingsCard.form.mode.label')}}
                            <el-tooltip :content="$t('entityViews.newCamp.settingsCard.form.mode.tooltip')" :open-delay="300" placement="top">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </el-tooltip>
                        </label>
                        <el-select class="select-primary"
                                   v-model="anonymousMode">
                            <el-option v-for="am in anynomousModes"
                                       class="select-primary"
                                       :value="am.value"
                                       :label="am.label"
                                       :key="am.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group" data-v-step="step-template">
                        <label class="">{{$t('entityViews.newCamp.settingsCard.form.cbg.label')}}</label>
                        <fg-input :error="modelValidations.selectedCbGroupHID.msg">
                            <el-select class="select-primary" filterable :disabled="cbgSelectorLoading"
                                       :placeholder="cbgSelectorPlaceholder"
                                       v-model="selectedCbGroupHID">
                                <el-option v-for="ct in campaignBlockGroups"
                                           class="select-primary"
                                           :value="ct.hid"
                                           :label="ct.selectLabel"
                                           :key="ct.hid">
                                    <span style="float: left">{{ ct.name }} </span>
                                    <span style="float: right">{{ct.cultureCode}}</span>
                                </el-option>
                            </el-select>
                        </fg-input>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group" data-v-step="step-group">
                        <label class="">{{$t('entityViews.newCamp.settingsCard.form.hvg.label')}}</label>
                        <fg-input :error="modelValidations.selectedHvGroupHID.msg">
                            <el-select class="select-primary" filterable :disabled="hvgSelectorLoading"
                                       :placeholder="hvgSelectorPlaceholder"
                                       v-model="selectedHvGroupHID">
                                <el-option v-for="hvg in hvGroups"
                                           class="select-primary"
                                           :value="hvg.hid"
                                           :label="translateGroupName(hvg.name, hvg.isSystem)"
                                           :key="hvg.hid">
                                    <span style="float: left">{{ translateGroupName(hvg.name, hvg.isSystem) }}</span>
                                    <div style="float: right">
                                        <el-tooltip :content="$t('entityViews.newCamp.settingsCard.form.hvg.domainNotValid')" :open-delay="300" placement="top" v-if="!hvg.checkedDomains">
                                            <i class="fa fa-ban" aria-hidden="true"></i>
                                        </el-tooltip>
                                        <el-tooltip :content="$t('entityViews.newCamp.settingsCard.form.hvg.runningCamp')" :open-delay="300" placement="top" v-if="hvg.hasRunningCampaign">
                                            <i class="fa-solid fa-chart-gantt text-success" aria-hidden="true"></i>
                                        </el-tooltip>
                                    </div>
                                </el-option>
                            </el-select>
                        </fg-input>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <p-checkbox v-model="isQA">{{$t('entityViews.newCamp.schedulerCard.qaTitle')}}</p-checkbox>
                </div>
            </div>

        </card>

        <card data-v-step="step-calendar">
            <div slot="header">
                <h4 class="card-title">
                    {{$t('entityViews.newCamp.schedulerCard.header')}}
                </h4>
                <p>{{$t('entityViews.newCamp.schedulerCard.text')}}</p>
            </div>

            <div class="row">
                <div class="col">
                    <p class="text-danger">{{modelValidations.campaignScheduler.msg}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="">{{$t('entityViews.newCamp.schedulerCard.form.startDate.label')}}</label>
                        <el-date-picker v-model="startDateUtc" type="date" :placeholder="$t('entityViews.newCamp.schedulerCard.form.startDate.placeholder')"
                                        :picker-options="datePickerOptions" :default-value="defaultStartDate" readonly>
                        </el-date-picker>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="">{{$t('entityViews.newCamp.schedulerCard.form.endDate.label')}}</label>
                        <el-date-picker v-model="endDateUtc" type="date" :placeholder="$t('entityViews.newCamp.schedulerCard.form.endDate.placeholder')"
                                        :picker-options="datePickerOptions" readonly>
                        </el-date-picker>
                    </div>
                </div>
            </div>

            <div class="row mt-3" ref="campScheduler">
                <div class="col-12 col-md-10 ml-md-auto mr-md-auto">
                    <campaign-scheduler :minDate="minStartDateCalendar" :maxDate="maxStartDateCalendar"
                                        @onClickDay="onClickDayHandler"
                                        :newCampaign="newCampaignForScheduler"
                                        :campaignSchedulerData="campaignSchedulerData">
                    </campaign-scheduler>
                </div>
            </div>

            <div class="row mt-3" v-if="modelValidations.summary.msg.length > 0">
                <div class="col text-center">
                    <span class="validation-summary">
                        {{modelValidations.summary.msg}}
                    </span>
                </div>
            </div>


            <div class="row mt-3 justify-content-center">
                <div class="col-12 col-md-auto text-center text-md-right">
                    <p-button type="warning" @click="createCampaign" class="w-100" v-if="isQA">
                        {{$t('entityViews.newCamp.btnQaCreate')}}
                    </p-button>
                    <p-button type="success" @click="createCampaign" class="w-100" v-else>
                        {{$t('entityViews.newCamp.btnCreate')}}
                    </p-button>
                </div>
                <div class="col-12 col-md-auto">
                    <router-link class="btn btn-info w-100" :to="{ name: 'eCampaignsList'}">
                        {{$t('entityViews.newCamp.btnCancel')}}
                    </router-link>
                </div>
            </div>
        </card>

    </div>
</template>
<script>
    import { MaturityLevelTools, LazyAITools } from 'src/lpLibrary.js'
    import { Collapse, CollapseItem, Card, Checkbox } from 'src/components/UIComponents'
    import { Select, DatePicker, Option } from 'element-ui'
    import campaignScheduler from 'src/components/UIComponents/CampaignScheduler.vue';
    import swal from 'sweetalert2'

    export default {
        components: {
            [DatePicker.name]: DatePicker,
            [Select.name]: Select,
            [Option.name]: Option,
            CollapseItem,
            Collapse,
            [Checkbox.name]: Checkbox,
            [campaignScheduler.name]: campaignScheduler,
            Card
        },
        data() {
            return {
                entityHID: null,
                countCampsPerDay: [],
                selectedCbGroup : null,
                selectedCbGroupHID: null,
                selectedHvGroup: null,
                selectedHvGroupHID: null,
                startDateUtc: null,
                endDateUtc: null,
                anonymousMode: false,
                campaignBlockGroups: [],
                hvGroups: [],
                datePickerOptions: {
                    disabledDate(time) {
                        return vm.disabledDateDatePicker(time);
                    },
                    shortcuts: [{
                        text: 'Today',
                        onClick(picker) {
                            picker.$emit('pick', new Date())
                        }
                    },
                    ]
                },
                campaignName: null,
                modelValidations: {
                    campaignName: {
                        msg:'',
                    },
                    selectedCbGroupHID: {
                        msg: '',
                    },
                    selectedHvGroupHID: {
                        msg: '',
                    },
                    startDateUtc: {
                        msg: '',
                    },
                    campaignScheduler: {
                        msg: '',
                    },
                    summary: {
                        msg:'',
                    },
                },
                newCampaignForScheduler: {
                    id: -1,
                    name: "",
                    startDate: null,
                    endDate: null,
                    color: "#e3e3e3", // planovana
                },
                campaignSchedulerData: {},
                urlParams: {
                    campBlocksHID: null,
                    groupHID: null,
                },
                cbgSelectorLoading: true,
                hvgSelectorLoading: true,   
                isQA: false,
            }
        },
        methods: {
            initComponent: function () {
                this.loadCampBlockGroups();
                this.loadHvGroups();
                this.loadCampaignSchedulerData();
            },
            createCampaign: function () {
                if (this.validate()) {
                    let result = this.checkConcurrentCampaigns();
                    if (!result) {
                        this.campLimitErrorDialog();
                        return;
                    }

                    var cModel = {
                        campaignName: this.campaignName.trim(),
                        hvGroupHID: this.selectedHvGroup.hid,
                        campaignBlockGroupHID: this.selectedCbGroup.hid,
                        startDateUtc: new Date(Date.UTC(this.startDateUtc.getFullYear(), this.startDateUtc.getMonth(), this.startDateUtc.getDate())),
                        endDateUtc: new Date(Date.UTC(this.endDateUtc.getFullYear(), this.endDateUtc.getMonth(), this.endDateUtc.getDate())),
                        sendingMode: this.selectedSendMode,
                        anonymousMode: this.anonymousMode,
                        isQA: this.isQA,
                    };
                    swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                    let vueThis = this;
                    this.axios.post(this.$root.config.entity.campaign.createCampaignUrl(this.entityHID), cModel)
                        .then(response => {
                            swal.close();
                            vueThis.$router.push({ name: 'eCampaignsList' });
                            swal.fire({
                                icon: 'success',
                                title: vueThis.$t('entityViews.newCamp.alert.success'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }).catch(function (error) {
                            swal.close();
                            if (error.response.status == 400) {
                                // 100503, 500003, 500505, 500503:
                                let respMsg = error.response.data;
                                swal.fire({
                                    title: vueThis.$t('entityViews.newCamp.alert.failed'),
                                    text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: cModel.campaignName, hvsHvgQALimit: vueThis.$root.config.maxHvsInHVGForQACampain } ),
                                    icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }
                            else {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                            }
                        });
                }
            },
            loadCampBlockGroups() {
                if (!this.entityHID) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.campBlockGroup.getCampaignBlockGroupsUrl(this.entityHID), null)
                    .then(response => {
                        this.campaignBlockGroups = response.data;
                        this.campaignBlockGroups.forEach(function (item) {
                            item.selectLabel = `${item.name} (${item.cultureCode})`;
                        });
                        if (this.urlParams.campBlocksHID) {
                            if (this.campaignBlockGroups.some(c => c.hid == this.urlParams.campBlocksHID)) {
                                this.selectedCbGroupHID = this.urlParams.campBlocksHID;
                            }
                        }
                        this.cbgSelectorLoading = false;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        this.cbgSelectorLoading = false;
                    });
            },
            setEndDate() {
                if (this.selectedCbGroup != null && this.startDateUtc != null) {
                    this.endDateUtc = new Date(this.startDateUtc);
                    this.endDateUtc.setDate(this.startDateUtc.getDate() + this.selectedCbGroup.sending + this.selectedCbGroup.waiting);
                }
            },
            disabledDateDatePicker(time) {
                //let tDate = new Date(time.getFullYear(), time.getMonth(), time.getDate());
                //let faDate = new Date(this.firstAvailableDate.getFullYear(), this.firstAvailableDate.getMonth(), this.firstAvailableDate.getDate());
                //let nowDate = new Date(Date.now());
                //let laDate = new Date(nowDate.getFullYear() + this.$root.config.startDateCampaignRange, nowDate.getMonth(), nowDate.getDate());
                //let disableByStandartRange = tDate < faDate || tDate > laDate
                
                //if (!disableByStandartRange) {
                //    let tDateUtc = new Date(Date.UTC(tDate.getFullYear(), tDate.getMonth(), tDate.getDate()));
                //    let strtDateUtc = tDateUtc.toISOString().split('.')[0] + "Z";
                //    //console.log(tDateUtc, strtDateUtc, this.countCampDays[0].date);
                //    let cdItem = this.countCampDays.find(c => c.date == strtDateUtc);
                //    if (cdItem != undefined) {
                //        if (cdItem.count >= 3) return true;
                //    }
                //}
                //return disableByStandartRange
                return false;
            },
            loadHvGroups() {
                if (!this.entityHID) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.hvGroup.getHvGroupsUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.hvGroups = response.data;
                        if (vueThis.urlParams.groupHID) {
                            if (vueThis.hvGroups.some(c => c.hid == vueThis.urlParams.groupHID)) {
                                vueThis.selectedHvGroupHID = vueThis.urlParams.groupHID;
                            }
                        }
                        vueThis.hvgSelectorLoading = false;
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        vueThis.hvgSelectorLoading = false;
                    });
            },
            validate: function () {
                var result = true;
                this.clearValidation();
                if (this.campaignName == null) {
                    this.modelValidations.campaignName.msg = this.$t('entityViews.newCamp.settingsCard.form.name.errReq');
                    result = false;
                }
                else {
                    if (this.campaignName.trim().length == 0) {
                        this.modelValidations.campaignName.msg = this.$t('entityViews.newCamp.settingsCard.form.name.errReq');
                        result = false;
                    }
                    if (this.campaignName.trim().length > this.$root.config.maxCampainNameLength) {
                        this.modelValidations.campaignName.msg = this.$t('entityViews.newCamp.settingsCard.form.name.errMax', [this.$root.config.maxCampainNameLength]);
                        result = false;
                    }
                }
                if (this.selectedCbGroupHID == null) {
                    this.modelValidations.selectedCbGroupHID.msg = this.$t('entityViews.newCamp.settingsCard.form.cbg.errReq');
                    result = false;
                }
                if (this.selectedHvGroupHID == null) {
                    this.modelValidations.selectedHvGroupHID.msg = this.$t('entityViews.newCamp.settingsCard.form.hvg.errReq');
                    result = false;
                }
                else {
                    let hvg = this.hvGroups.find(e => e.hid == this.selectedHvGroupHID);
                    if (hvg.countHoaxVictim == 0) {
                        this.modelValidations.selectedHvGroupHID.msg = this.$t('entityViews.newCamp.settingsCard.form.hvg.errNoHvs');
                        result = false;
                    }
                    else if (!hvg.checkedDomains) {
                        this.modelValidations.selectedHvGroupHID.msg = this.$t('entityViews.newCamp.settingsCard.form.hvg.errNoValidDomain');
                        result = false;
                    }
                }
                if (this.isQA && this.selectedHvGroup.countHoaxVictim > this.$root.config.maxHvsInHVGForQACampain) {                   
                    this.modelValidations.selectedHvGroupHID.msg = this.$t('apiErrorCode.' + 500506 + '.msg', { hvsHvgQALimit: this.$root.config.maxHvsInHVGForQACampain });
                    result = false;
                }
                if (!this.readOnlyStartDatePicker && (this.startDateUtc == null || this.startDateUtc.length == 0)) {
                    this.modelValidations.campaignScheduler.msg = this.$t('entityViews.newCamp.schedulerCard.form.startDate.errReq');
                    result = false;
                }


                if (result) {
                    this.modelValidations.summary.msg = "";
                }
                else {
                    this.modelValidations.summary.msg = this.$t('entityViews.newCamp.errExist');
                }

                return result;
            },
            clearValidation: function() {
                this.modelValidations.campaignName.msg = "";
                this.modelValidations.selectedCbGroupHID.msg = "";
                this.modelValidations.selectedHvGroupHID.msg = "";
                this.modelValidations.startDateUtc.msg = "";
                this.modelValidations.campaignScheduler.msg = "";
                this.modelValidations.summary.msg = "";
            },
            loadCampaignSchedulerData() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.campScheduler)); 
                this.axios.post(this.$root.config.entity.campaign.campaignSchedulerUrl(this.entityHID), null)
                    .then(response => {
                        loader.hide();
                        this.campaignSchedulerData = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        loader.hide();
                    });
            },
            onClickDayHandler(date) {
                this.startDateUtc = date;
            },
            checkConcurrentCampaigns() {
                this.modelValidations.campaignScheduler.msg = "";
                let dt = new Date(this.startDateUtc.getTime());
                while (dt <= this.endDateUtc) {
                    //console.log(dt);
                    let tDateUtc = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate()));
                    let strtDateUtc = tDateUtc.toISOString().split('.')[0] + "Z";
                    let cdItem = this.campaignSchedulerData.campaignCountDays.find(c => c.date == strtDateUtc);
                    if (cdItem != undefined) {
                        if (cdItem.count >= this.campaignSchedulerData.maxConcurrentCampaigns) {
                            this.modelValidations.campaignScheduler.msg = this.$t('entityViews.newCamp.schedulerCard.errCampLimit');
                            return false;
                        }
                    }
                    dt = new Date(dt.setDate(dt.getDate() + 1));
                }
                return true
            },
            updateCampaignForScheduler() {
                this.newCampaignForScheduler.startDate = this.startDateUtc;
                this.newCampaignForScheduler.endDate = this.endDateUtc;

                let cn = this.campaignName;
                if (cn == null || cn.length == 0)
                    cn = "";
                this.newCampaignForScheduler.name = cn;
            },
            campLimitErrorDialog() {
                swal.fire({
                    icon: 'error',
                    title: this.$t('entityViews.newCamp.campLimitErr'),
                    text: this.$t('apiErrorCode.500503.msg'),
                    showConfirmButton: true,
                    timer: this.$root.config.alertTimer15,
                    timerProgressBar: true,
                });
            },
            translateGroupName(name, isSystem) {
                return MaturityLevelTools.translateGroupName(name, isSystem);
            },
        },
        computed: {
            defaultStartDate() {
                return this.$moment(this.firstAvailableDate).format('YYYY-MM-DD')
            },
            anynomousModes() {
                return [{ label: this.$t('entityViews.newCamp.modes.withTP'), value: false }, { label: this.$t('entityViews.newCamp.modes.noTP'), value: true}];
            },
            readOnlyStartDatePicker() {
                return !(this.selectedCbGroup != null && this.selectedCbGroup != undefined  && this.selectedCbGroup.hid != null && this.selectedCbGroup.hid != undefined);
            },
            minStartDateCalendar() {
                let now = new Date(Date.now());
                return new Date(now.setDate(now.getDate() - 1));
            },
            maxStartDateCalendar() {
                let nowDate = new Date(Date.now());
                return new Date(nowDate.getFullYear() + this.$root.config.startDateCampaignRange, nowDate.getMonth(), nowDate.getDate());
            },
            cbgSelectorPlaceholder() {
                if (this.cbgSelectorLoading) {
                    return this.$t('entityViews.newCamp.settingsCard.form.cbg.loading');
                }
                return this.$t('entityViews.newCamp.settingsCard.form.cbg.placeholder');
            },
            hvgSelectorPlaceholder() {
                if (this.hvgSelectorLoading) {
                    return this.$t('entityViews.newCamp.settingsCard.form.hvg.loading');
                }
                return this.$t('entityViews.newCamp.settingsCard.form.hvg.placeholder');
            },
        },
        watch: {
            campaignName: function (value) {
                this.validate();
                this.updateCampaignForScheduler();
            },
            startDateUtc: function (value) {
                this.setEndDate();
                this.validate();
                this.updateCampaignForScheduler();
                this.checkConcurrentCampaigns();
            },
            selectedCbGroup: function (value) {
                this.setEndDate();
                this.validate();
                this.updateCampaignForScheduler();
            },
            selectedCbGroupHID: function (value) {
                this.selectedCbGroup = this.campaignBlockGroups.find(c => c.hid == value);
                this.validate();
            },
            selectedHvGroupHID: function (value) {
                this.selectedHvGroup = this.hvGroups.find(c => c.hid == value);
                if (!this.hvgSelectorLoading)
                    this.validate();
            },
            $route(to, from) {
                if (to !== from) {
                    //this.entityHID = this.$route.params.ehid;
                    //this.initComponent();
                    //this.selectedHvGroupHID = null;
                    //this.selectedHvGroup = null;
                    this.$router.push({ name: 'eCampaignsList', params: { ehid: this.$route.params.ehid } })
                }
            }
        },
        mounted() {
            this.initComponent();
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            if (this.$route.query.cbhid)
                this.urlParams.campBlocksHID = this.$route.query.cbhid;
            if (this.$route.query.ghid)
                this.urlParams.groupHID = this.$route.query.ghid;

        }
    }
</script>
<style scoped>

</style>