<template>
    <card class="benchmark-card">
        <div class="row row-cols-2" slot="header" v-if="mode === 'info'">
            <div class="col-8">
                <h4 class="card-title">{{title}}</h4>
            </div>
            <div class="col-4 text-right" :style="{color: iconColor}">
                <slot name="icon">
                    <i class="fa-3x" :class="icon" ></i>
                </slot>
            </div>
        </div>
        <div class="row row-cols-2" slot="header" v-if="mode === 'diff'">
            <div class="col-12">
                <h4 class="card-title text-center">{{title}}</h4>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-12 text-reacted" :style="{color: reactedColor}" v-if="mode === 'info'">
                <h5 class="text-left">{{$t('cards.benchmarkCard.reactedHeader')}}</h5>
                <div class="row">
                    <div class="col-auto text-left">
                        <span class="value-percent">
                            {{reacted}}%
                        </span>
                    </div>
                    <div class="col text-right">
                        <i class="fa-solid fa-hand-point-up  fa-3x"></i>
                    </div>
                </div>
                <div class="row" v-if="mode === 'diff'">
                    <div class="col-12 text-center">
                        <span class="value-percent">
                            {{reacted}}%
                        </span>
                    </div>
                </div>
                <el-progress type="line" :percentage="reacted" :color="reactedColor" :show-text="false"></el-progress>
            </div>
            <div class="col-12 text-reacted" v-if="mode === 'diff'">
                <h5 class="text-center" :style="{color: reactedColor}">{{$t('cards.benchmarkCard.reactedHeader')}}</h5>
                <div class="row">
                    <div class="col-12 text-center" :style="{color: reactedSemaforColor}">
                        <span class="value-percent">
                            {{reacted}}%
                        </span>
                    </div>
                </div>
                <el-progress type="line" :percentage="100" :color="reactedSemaforColor" :show-text="false" v-if="mode === 'diff'"></el-progress>
            </div>



            <div class="col-12 mt-5 text-compromised" :style="{color: compromisedColor}" v-if="mode === 'info'">
                <h5 class="text-left">{{$t('cards.benchmarkCard.compromisedHeader')}}</h5>
                <div class="row">
                    <div class="col-auto text-left ">
                        <span class="value-percent">{{compromised}}%</span>
                    </div>
                    <div class="col text-right" v-if="mode === 'info'">
                        <i class="fa fa-bomb  fa-3x"></i>
                    </div>
                </div>
                <el-progress type="line" :percentage="compromised" :color="compromisedColor" :show-text="false"></el-progress>
            </div>
            <div class="col-12 mt-5 text-compromised"  v-if="mode === 'diff'">
                <h5 class="text-center" :style="{color: compromisedColor}">{{$t('cards.benchmarkCard.compromisedHeader')}}</h5>
                <div class="row">
                    <div class="col-12 text-center" :style="{color: compromisedSemaforColor}">
                        <span class="value-percent">{{compromised}}%</span>
                    </div>
                </div>
                <el-progress type="line" :percentage="100" :color="compromisedSemaforColor" :show-text="false"></el-progress>
            </div>
        </div>

        <div slot="footer" class="text-muted text-left">
            {{footerText}}
        </div>

    </card>
</template>

<script>
    import { Progress, Divider } from 'element-ui'

    export default {
        name: 'benchmark-card',
        components: {
            [Progress.name]: Progress,
            [Divider.name]: Divider,
        },
        props: {
            color: {
                type: String,
                default: null,
            },
            icon: {
                type: String,
                default: "fa-solid fa-file-shield",
            },
            title: {
                type: String,
            },
            footerText: {
                type: String,
            },
            reacted: {
                type: Number,
            },
            compromised: {
                type: Number,
            },
            mode: {
                type: String,
                default: "info",
                description: "info|diff"
            }
        },
        methods: {
            diffSemaforColor(value) {
                if (value > 0) {
                    return "#41EC2D"; // zelena
                }
                else if (value < 0 && value > -4) {
                    return "#FFFF00"; // zluta
                }
                else {
                    return "#FF0000"; // cervena
                }
            }
        },
        computed: {
            iconColor() {
                //if(this.color == null)
                    return "#6d6d6c";
                //return this.color;
            },
            reactedColor() {
                if (this.mode === 'info') {
                    if (this.color == null)
                        return "#ffa500";
                    return this.color;
                }
                if (this.mode === 'diff') {
                    return "#6d6d6c";
                }
            },
            compromisedColor() {
                if (this.mode === 'info') {
                    if (this.color == null)
                        return "#ef8157";
                    return this.color;
                }
                if (this.mode === 'diff') {
                    return "#6d6d6c";
                }
            },
            reactedSemaforColor() {
                return this.diffSemaforColor(this.reacted);

            },
            compromisedSemaforColor() {
                return this.diffSemaforColor(this.compromised);
            },

        },
    }
</script>

<style scoped>
</style>